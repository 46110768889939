import { useEffect, useState, useMemo } from "react"
import { useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import Imgix from "react-imgix"
import toast from "react-hot-toast"
import {
  updateCompany,
  publishCompany,
  deleteCompany,
  updateCompanyReviewDate,
} from "api/companies"
import { formatDateTime, formatReviewDate } from "utils/format"
import { useSelector } from "react-redux"

import Modal from "components/Modal"
import Input from "components/forms/Input"
import Icon from "components/Icon"
import ImageUpload from "components/forms/ImageUpload"
import EnumSelect from "components/companies/EnumSelect"
import IndustrySelect from "components/industries/Select"
import CompanySelect from "components/companies/Select"
import SellingPersonasSelect from "components/selling_personas/Select"
import Comments from "components/comments"
import MergeModal from "components/companies/MergeModal"
import TagSelect from "components/tags/Select"
import BusinessUnitsTable from "components/companies/business_units"

import "./Profile.scss"

const CompanyProfileRoute = ({ company, setCompany }) => {
  const {
    id,
    name,
    logo,
    white_logo,
    description,
    tagline,
    website,
    linkedin_url,
    careers_url,
    slug,
    old_slugs,
    industry,
    parent,
    size,
    year_founded,
    selling_personas,
    funding_source,
    benefits_active,
    culture_active,
    publish_on_hold,
    flagged_for_question,
    free_preview,
    phone_required,
    created_at,
    fully_publishable,
    partially_publishable,
    published_at,
    published_status,
    discarded_at,
    icp_flag,
    children,
    comments,
    frequency,
    next_review_date,
    tags,
    ticker,
    resume_required,
    fiscal_year_end,
    business_units,
  } = company

  const [editing, setEditing] = useState(false)
  const [errors, setErrors] = useState({})
  const [showMergeModal, setShowMergeModal] = useState(false)
  const [logoBg, setLogoBg] = useState(false)
  const [whiteLogoBg, setWhiteLogoBg] = useState(true)

  const { register, handleSubmit, control, reset, setValue } = useForm()
  const { currentAdmin } = useSelector(state => state.auth)
  const { access_level } = currentAdmin

  const limitedAccess = access_level === "limited"

  const defaultValues = useMemo(
    () => ({
      name: company.name,
      description: company.description,
      tagline: company.tagline,
      website: company.website,
      linkedin_url: company.linkedin_url,
      careers_url: company.careers_url,
      industry_id: company?.industry?.id,
      parent_id: company?.parent,
      size: company?.size,
      year_founded: company?.year_founded,
      slug: company?.slug,
      selling_persona_ids: company?.selling_personas?.map(
        persona => persona.id
      ),
      funding_source: company?.funding_source,
      frequency: company?.frequency,
      tag_ids: company?.tags?.map(tag => tag.id),
      ticker: company?.ticker,
      resume_required: company?.resume_required,
      fiscal_year_end: company?.fiscal_year_end,
    }),
    [company]
  )

  const sellingPersonasList =
    selling_personas &&
    selling_personas.map(sp => (
      <span key={sp.id} className="badge rounded-pill bg-primary me-2">
        {sp.name}
      </span>
    ))

  const tagsList =
    tags &&
    tags.map(tag => (
      <span key={tag.id} className="badge rounded-pill bg-success me-2">
        {tag.name}
      </span>
    ))

  // since the company data is coming in async
  useEffect(() => {
    reset({ ...defaultValues })
  }, [reset, defaultValues])

  const handleUpdate = data => {
    if (data.logo === "") delete data["logo"]
    if (data.white_logo === "") delete data["white_logo"]
    if (data.careers_url === "") delete data["careers_url"]
    if (data.ticker) data.ticker = data.ticker.toUpperCase()

    updateCompany(id, data)
      .then(response => {
        setCompany(response.data)
        toast.success("Update successful")
        setEditing(false)
        setErrors({})
      })
      .catch(response => {
        setErrors(response?.data)
      })
  }

  const handlePartialPublish = e => {
    e.preventDefault()
    const params = { status: "partial" }
    if (
      window.confirm(
        "Are you sure you want to partially publish this company?"
      ) === true
    ) {
      publishCompany(id, params)
        .then(response => {
          setCompany(response.data)
          toast.success("Company partially published!")
        })
        .catch(() => {
          // setLoading(false)
        })
    }
  }

  const handlePublish = e => {
    e.preventDefault()
    if (
      window.confirm("Are you sure you want to fully publish this company?") ===
      true
    ) {
      publishCompany(id)
        .then(response => {
          setCompany(response.data)
          toast.success("Company fully published!")
        })
        .catch(() => {
          // setLoading(false)
        })
    }
  }

  const handleReview = () => {
    updateCompanyReviewDate(id)
      .then(res => {
        setCompany(res.data)
        toast.success("Review submitted")
      })
      .catch(error => console.error(error))
  }

  const handleDelete = e => {
    e.preventDefault()
    if (
      window.confirm("Are you sure you want to delete this company?") === true
    ) {
      deleteCompany(id)
        .then(response => {
          setCompany(response.data)
          toast.success("Company deleted")
        })
        .catch(() => {
          // setLoading(false)
        })
    }
  }

  const toggleFlag = () => {
    const data = { flagged_for_question: !flagged_for_question }
    updateCompany(id, data)
      .then(response => {
        setCompany(response.data)
      })
      .catch(response => {
        setErrors(response?.data)
      })
  }

  const cancelEditing = () => {
    setEditing(false)
    setErrors({})
    reset({ ...defaultValues })
  }

  const getErrors = name => {
    return errors[name] || []
  }

  const closeModal = () => {
    setShowMergeModal(false)
  }

  const prependComment = comment => {
    setCompany({ ...company, comments: [comment, ...comments] })
  }

  const getPublishMessage = () => {
    if (fully_publishable) {
      return "This company is eligible to be fully published."
    }
    if (published_status === "Partial") {
      if (!fully_publishable) {
        return "This company does not meet the criteria to be fully published."
      }
    } else {
      if (partially_publishable) {
        return "This company is eligible to be partially published."
      } else {
        return "This company does not meet the criteria to be published."
      }
    }
  }

  const nextReviewDate = formatReviewDate(next_review_date)

  const showPublishedWidget = published_status !== "Full"
  const publishTitle =
    fully_publishable || published_status === "Partial"
      ? "Fully Publish"
      : "Partially Publish"

  return (
    <>
      <div className="tab-header">
        <h3>
          {icp_flag && <Icon name="flag" height={20} width={20} />}
          {flagged_for_question && (
            <Icon name="questionFlag" className="question me-2" />
          )}
          Profile
        </h3>
        <div>
          {!editing ? (
            <button
              className="btn btn-primary"
              onClick={() => setEditing(true)}
            >
              Edit
            </button>
          ) : (
            <div>
              <button className="btn btn-light me-2" onClick={cancelEditing}>
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={handleSubmit(handleUpdate)}
              >
                Save
              </button>
            </div>
          )}
          <button
            className="btn btn-warning question-flag ms-2"
            onClick={() => toggleFlag()}
          >
            {flagged_for_question ? "Unflag" : "Flag"}
          </button>
        </div>
      </div>
      <div className="tab-container">
        <div className="company-profile">
          <form className="profile-fields">
            <div className={`editable-table ${editing ? "editing" : ""}`}>
              <table className="table horizontal table-compressed">
                <tbody>
                  <tr>
                    <th>ID</th>
                    <td>{id}</td>
                  </tr>
                  <tr>
                    <th>Name</th>
                    <td>
                      <div className="form-value">{name}</div>
                      <div className="form-field">
                        <Input
                          className="form-control-sm"
                          type="text"
                          name="name"
                          error={getErrors("name")[0]}
                          register={register}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Logo</th>
                    <td className={`${logoBg ? "dark" : ""}`}>
                      <div className="form-value logo">
                        <div>
                          {logo ? (
                            <Imgix
                              src={`${process.env.REACT_APP_IMAGE_URL}/${logo}`}
                              alt={name}
                              width={50}
                              height={50}
                            />
                          ) : (
                            <Icon name="logo" height={40} width={40} />
                          )}
                        </div>
                        <div className="form-check pe-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={logoBg}
                            onChange={() => setLogoBg(!logoBg)}
                            id="bg-toggle"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="bg-toggle"
                          >
                            Dark background
                          </label>
                        </div>
                      </div>

                      <div className="form-field logo">
                        {editing && (
                          <ImageUpload
                            handleChange={setValue}
                            register={register}
                            name="logo"
                            type="logo"
                            value={
                              logo
                                ? `${process.env.REACT_APP_IMAGE_URL}/${logo}`
                                : undefined
                            }
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>White Logo</th>
                    <td className={`${whiteLogoBg ? "dark" : ""}`}>
                      <div className="form-value logo">
                        <div>
                          {white_logo ? (
                            <Imgix
                              src={`${process.env.REACT_APP_IMAGE_URL}/${white_logo}`}
                              alt={name}
                              width={50}
                              height={50}
                            />
                          ) : (
                            <Icon name="logo" height={40} width={40} />
                          )}
                        </div>
                        <div className="form-check pe-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={whiteLogoBg}
                            onChange={() => setWhiteLogoBg(!whiteLogoBg)}
                            id="bg-toggle"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="bg-toggle"
                          >
                            Dark background
                          </label>
                        </div>
                      </div>

                      <div className="form-field logo">
                        {editing && (
                          <ImageUpload
                            handleChange={setValue}
                            register={register}
                            name="white_logo"
                            type="logo"
                            value={
                              white_logo
                                ? `${process.env.REACT_APP_IMAGE_URL}/${white_logo}`
                                : undefined
                            }
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Industry</th>
                    <td>
                      {!editing ? (
                        <div className="form-value">
                          {industry?.name || String.fromCharCode(8212)}
                        </div>
                      ) : (
                        <div className="form-field">
                          <IndustrySelect
                            name="industry_id"
                            control={control}
                            error={getErrors("industry_id")[0]}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Tagline</th>
                    <td>
                      <div className="form-value">
                        {tagline || String.fromCharCode(8212)}
                      </div>
                      <div className="form-field">
                        <Input
                          className="form-control-sm"
                          type="text"
                          name="tagline"
                          error={getErrors("tagline")[0]}
                          register={register}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="align-top">Description</th>
                    <td>
                      <div className="form-value text-field">
                        {description || String.fromCharCode(8212)}
                      </div>
                      <div className="form-field">
                        <textarea
                          className="form-control form-control-sm"
                          rows="4"
                          {...register("description")}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Size</th>
                    <td>
                      {!editing ? (
                        <div className="form-value">
                          {size || String.fromCharCode(8212)}
                        </div>
                      ) : (
                        <div className="form-field">
                          <EnumSelect
                            name="size"
                            field="size"
                            control={control}
                            error={getErrors("size")[0]}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Year Founded</th>
                    <td>
                      {!editing ? (
                        <div className="form-value">
                          {year_founded || String.fromCharCode(8212)}
                        </div>
                      ) : (
                        <div className="form-field">
                          <Input
                            className="form-control-sm"
                            type="number"
                            name="year_founded"
                            error={getErrors("year_founded")[0]}
                            register={register}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Parent Company</th>
                    <td>
                      {!editing ? (
                        <div className="form-value">
                          {parent ? (
                            <Link to={`/companies/${parent.id}`}>
                              {parent.name}
                            </Link>
                          ) : (
                            String.fromCharCode(8212)
                          )}
                        </div>
                      ) : (
                        <div className="form-field">
                          <CompanySelect
                            name="parent_id"
                            control={control}
                            value={parent}
                            error={getErrors("parent_id")[0]}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Website</th>
                    <td>
                      <div className="form-value">
                        {website ? (
                          <a href={website} target="_blank" rel="noreferrer">
                            {website}
                          </a>
                        ) : (
                          String.fromCharCode(8212)
                        )}
                      </div>
                      <div className="form-field">
                        <Input
                          className="form-control-sm"
                          type="text"
                          name="website"
                          error={getErrors("website")[0]}
                          register={register}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>LinkedIn URL</th>
                    <td>
                      <div className="form-value">
                        {linkedin_url ? (
                          <a
                            href={linkedin_url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {linkedin_url}
                          </a>
                        ) : (
                          String.fromCharCode(8212)
                        )}
                      </div>
                      <div className="form-field">
                        <Input
                          className="form-control-sm"
                          type="text"
                          name="linkedin_url"
                          error={getErrors("linkedin_url")[0]}
                          register={register}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Careers URL</th>
                    <td>
                      <div className="form-value">
                        {careers_url ? (
                          <a
                            href={careers_url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {careers_url}
                          </a>
                        ) : (
                          String.fromCharCode(8212)
                        )}
                      </div>
                      <div className="form-field">
                        <Input
                          className="form-control-sm"
                          type="text"
                          name="careers_url"
                          error={getErrors("careers_url")[0]}
                          register={register}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Slug</th>
                    <td>
                      <div className="text-field">{slug}</div>
                      {/* <div className="form-field">
                        <Input
                          className="form-control-sm"
                          type="text"
                          name="slug"
                          error={getErrors("slug")[0]}
                          register={register}
                        />
                      </div> */}
                    </td>
                  </tr>
                  {old_slugs?.length > 0 && (
                    <tr>
                      <th>Previous Slug(s)</th>
                      <td>{old_slugs.join(", ")}</td>
                    </tr>
                  )}
                  <tr>
                    <th>Tag(s)</th>
                    <td>
                      {!editing ? (
                        <div className="form-value">
                          {tagsList?.length > 0
                            ? tagsList
                            : String.fromCharCode(8212)}
                        </div>
                      ) : (
                        <div className="form-field">
                          <TagSelect
                            name="tag_ids"
                            control={control}
                            error={getErrors("tag_ids")[0]}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Selling Persona(s)</th>
                    <td>
                      {!editing ? (
                        <div className="form-value">
                          {sellingPersonasList?.length > 0
                            ? sellingPersonasList
                            : String.fromCharCode(8212)}
                        </div>
                      ) : (
                        <div className="form-field">
                          <SellingPersonasSelect
                            name="selling_persona_ids"
                            control={control}
                            error={getErrors("selling_persona_ids")[0]}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Funding Source</th>
                    <td>
                      {!editing ? (
                        <div className="form-value">{funding_source}</div>
                      ) : (
                        <div className="form-field">
                          <EnumSelect
                            name="funding_source"
                            field="funding_source"
                            control={control}
                            error={getErrors("funding_source")[0]}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Fiscal Year End</th>
                    <td>
                      {!editing ? (
                        <div>{fiscal_year_end}</div>
                      ) : (
                        <div className="form-field">
                          <EnumSelect
                            field="fiscal_year_end"
                            name="fiscal_year_end"
                            error={getErrors("fiscal_year_end")[0]}
                            control={control}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Ticker</th>
                    <td>
                      {!editing ? (
                        <div className="form-value">
                          {ticker || String.fromCharCode(8212)}
                        </div>
                      ) : (
                        <div className="form-field">
                          <Input
                            className="text-uppercase"
                            type="text"
                            name="ticker"
                            error={getErrors("ticker")[0]}
                            register={register}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Benefits Active</th>
                    <td className="active-boolean">
                      {!editing ? (
                        <div className="form-value">
                          {benefits_active ? (
                            <Icon name="check" />
                          ) : (
                            String.fromCharCode(8212)
                          )}
                        </div>
                      ) : (
                        <div className="form-field">
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              defaultChecked={benefits_active}
                              {...register("benefits_active")}
                            />
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Culture Active</th>
                    <td className="active-boolean">
                      {!editing ? (
                        <div className="form-value">
                          {culture_active ? (
                            <Icon name="check" />
                          ) : (
                            String.fromCharCode(8212)
                          )}
                        </div>
                      ) : (
                        <div className="form-field">
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              defaultChecked={culture_active}
                              {...register("culture_active")}
                            />
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Free Preview</th>
                    <td className="active-boolean">
                      {!editing ? (
                        <div className="form-value">
                          {free_preview ? (
                            <Icon name="check" />
                          ) : (
                            String.fromCharCode(8212)
                          )}
                        </div>
                      ) : (
                        <div className="form-field">
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              defaultChecked={free_preview}
                              {...register("free_preview")}
                            />
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Require Phone Number for Jobs</th>
                    <td className="active-boolean">
                      {!editing ? (
                        <div className="form-value">
                          {phone_required ? (
                            <Icon name="check" />
                          ) : (
                            String.fromCharCode(8212)
                          )}
                        </div>
                      ) : (
                        <div className="form-field">
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              defaultChecked={phone_required}
                              {...register("phone_required")}
                            />
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Require Resume for Jobs</th>
                    <td className="active-boolean">
                      {!editing ? (
                        <div className="form-value">
                          {resume_required ? (
                            <Icon name="check" />
                          ) : (
                            String.fromCharCode(8212)
                          )}
                        </div>
                      ) : (
                        <div className="form-field">
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              defaultChecked={resume_required}
                              {...register("resume_required")}
                            />
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Publish on Hold</th>
                    <td className="active-boolean">
                      {!editing ? (
                        <div className="form-value">
                          {publish_on_hold ? (
                            <Icon name="check" />
                          ) : (
                            String.fromCharCode(8212)
                          )}
                        </div>
                      ) : (
                        <div className="form-field">
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              defaultChecked={publish_on_hold}
                              {...register("publish_on_hold")}
                            />
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Review Frequency</th>
                    <td>
                      {!editing ? (
                        <div>{frequency}</div>
                      ) : (
                        <div className="form-field">
                          <EnumSelect
                            field="frequency"
                            name="frequency"
                            error={getErrors("frequency")[0]}
                            control={control}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>

          <br />

          {business_units && (
            <BusinessUnitsTable
              business_units={business_units}
              company={company}
              setCompany={setCompany}
            />
          )}

          {children?.length > 0 && (
            <>
              <br />
              <div className="children-companies">
                <h4>Children Companies</h4>
                <div className="children-row">
                  {children.map(child => (
                    <Link key={child.id} to={`/companies/${child.id}`}>
                      {child.name}
                    </Link>
                  ))}
                </div>
              </div>
              <br />
            </>
          )}

          <div className="dates">
            <h4>Dates</h4>
            <div className="editable-table">
              <table className="table horizontal table-compressed">
                <tbody>
                  <tr>
                    <th>Created</th>
                    <td>{formatDateTime(created_at)}</td>
                  </tr>
                  <tr>
                    <th>Published</th>
                    <td>{formatDateTime(published_at)}</td>
                  </tr>
                  {discarded_at && (
                    <tr>
                      <th>Deleted</th>
                      <td>{formatDateTime(discarded_at)}</td>
                    </tr>
                  )}
                  <tr>
                    <th>Next Review Date</th>
                    <td className="review-row">
                      <div>
                        {next_review_date
                          ? nextReviewDate
                          : String.fromCharCode(8212)}
                        <button
                          className="btn btn-primary btn-sm d-block"
                          onClick={handleReview}
                        >
                          Mark Reviewed
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <br />

          <div className="big-moves">
            <h4>Big Moves</h4>
            {showPublishedWidget && (
              <div className="big-moves-content primary">
                <div>
                  <h6>{`${publishTitle} this company`}</h6>
                  <p>Company will be viewable on the user site.</p>
                  <p>
                    An email will be sent to anyone who has rated the company.
                  </p>
                  <p>Company will be announced in Slack channel.</p>
                  <p className="text-danger fw-semibold">
                    {getPublishMessage()}
                  </p>
                </div>
                {fully_publishable && (
                  <form onSubmit={handlePublish}>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={!fully_publishable}
                    >
                      Publish this company
                    </button>
                  </form>
                )}
                {!fully_publishable && (
                  <form onSubmit={handlePartialPublish}>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={!partially_publishable}
                    >
                      Publish this company
                    </button>
                  </form>
                )}
              </div>
            )}
            {!limitedAccess && (
              <>
                <div className="big-moves-content purple">
                  <div>
                    <h6>Merge this company</h6>
                    <p>
                      This company and its ratings will be merged with another.
                    </p>
                    <p>
                      After the merge, this company will no longer be accessible
                      as a stand-alone entity.
                    </p>
                  </div>
                  <button
                    className="btn btn-primary"
                    onClick={() => setShowMergeModal(true)}
                    disabled={limitedAccess}
                  >
                    Begin merge
                  </button>
                </div>
                {!discarded_at && (
                  <div className="big-moves-content danger">
                    <div>
                      <h6>Delete this company</h6>
                      <p>Company will be removed from the user site.</p>
                    </div>
                    <form onSubmit={handleDelete}>
                      <button
                        type="submit"
                        className="btn btn-danger"
                        disabled={limitedAccess}
                      >
                        Delete this company
                      </button>
                    </form>
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        <div className="company-sidebar">
          <Comments
            id={id}
            type="companies"
            comments={comments}
            addComment={prependComment}
          />
        </div>
      </div>

      <Modal
        title="Merge Company"
        show={showMergeModal}
        handleClose={closeModal}
      >
        <MergeModal name={name} id={id} />
      </Modal>
    </>
  )
}

export default CompanyProfileRoute
