import React, { useEffect, useState } from "react"
import dayjs from "dayjs"
import { getOperationsDashboard } from "api/dashboard"
import { Container, Row, Col } from "react-bootstrap"

import Header from "components/Header"
import Loading from "components/Loading"
import OperationsCard from "components/dashboard/OperationsCard"
import Icon from "components/Icon"

import "./Operations.scss"

const OperationsRoute = () => {
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getOperationsDashboard()
      .then(response => {
        setData(response.data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  const {
    fully_publishable_companies_count,
    partially_publishable_companies_count,
    not_reviewed_ratings,
    pending_questions,
    pending_answers,
    pending_new_to_sales,
    draft_jobs_count,
    pending_premium_jobs_count,
    pending_free_jobs_count,
    incomplete_companies_industry,
    incomplete_companies_size,
    hc_updates_pending_count,
    pending_admin_review_count,
    payment_pending_count,
    pending_reviews_count,
    pending_responses_count,
    pending_review_approvals_count,
    pending_question_approvals_count,
    pending_answer_approvals_count,
    pending_free_customer_count,
    companies_with_questions_count,
    ratings_with_questions_count,
  } = data

  const headcountDate = dayjs()
    .startOf("month")
    .subtract(1, "month")
    .format("YYYY-MM-DD")

  const today = dayjs().add(1, "day").format("YYYY-MM-DD")

  const ratingMetics = [
    {
      title: "Not Reviewed Ratings",
      link: "/ratings?filterModel[ratings.status][values][]=Not Reviewed&filterModel[ratings.status][filterType]=set&sortModel[][sort]=asc&sortModel[][colId]=ratings.created_at",
      icon: "ratings",
      count: not_reviewed_ratings,
    },
  ]

  const questionFlagMetrics = [
    {
      title: "Companies with Questions",
      link: "/companies?filterModel[companies.flagged_for_question][values][]=true&filterModel[companies.flagged_for_question][filterType]=set",
      count: companies_with_questions_count,
    },
    {
      title: "Ratings with Questions",
      link: "/ratings?filterModel[ratings.flagged_for_question][values][]=true&filterModel[ratings.flagged_for_question][filterType]=set",
      count: ratings_with_questions_count,
    },
  ]

  const companyMetrics = [
    {
      title: "Companies to be Published",
      link: "/companies?filterModel[companies.ratings_count][filterType]=number&filterModel[companies.ratings_count][type]=greaterThanOrEqual&filterModel[companies.ratings_count][filter]=6&filterModel[companies.verified_ratings_percent][filterType]=number&filterModel[companies.verified_ratings_percent][type]=greaterThan&filterModel[companies.verified_ratings_percent][filter]=70&filterModel[companies.repvue_score][filterType]=number&filterModel[companies.repvue_score][type]=greaterThan&filterModel[companies.repvue_score][filter]=0&filterModel[companies.publish_on_hold][values][]=false&filterModel[companies.publish_on_hold][filterType]=set&filterModel[companies.distinct_raters_count][filterType]=number&filterModel[companies.distinct_raters_count][type]=greaterThanOrEqual&filterModel[companies.distinct_raters_count][filter]=4&filterModel[companies.published_status][filterType]=text&filterModel[companies.published_status][operator]=OR&filterModel[companies.published_status][condition1][filterType]=text&filterModel[companies.published_status][condition1][type]=equals&filterModel[companies.published_status][condition1][filter]=Partial&filterModel[companies.published_status][condition2][filterType]=text&filterModel[companies.published_status][condition2][type]=blank&sortModel[][sort]=desc&sortModel[][colId]=companies.ratings_count",
      icon: "company",
      count: fully_publishable_companies_count,
    },
    {
      title: "Companies to be Partially Published",
      link: "/companies?filterModel[companies.ratings_count][filterType]=number&filterModel[companies.ratings_count][type]=greaterThanOrEqual&filterModel[companies.ratings_count][filter]=2&filterModel[companies.published_at][dateFrom]=&filterModel[companies.published_at][dateTo]=&filterModel[companies.published_at][filterType]=date&filterModel[companies.published_at][type]=dateFalse&filterModel[companies.verified_ratings_percent][filterType]=number&filterModel[companies.verified_ratings_percent][type]=greaterThan&filterModel[companies.verified_ratings_percent][filter]=0&filterModel[companies.size][values][]=1,001-10,000&filterModel[companies.size][values][]=251-1,000&filterModel[companies.size][values][]=51-250&filterModel[companies.size][values][]=10,001%2B&filterModel[companies.size][filterType]=set&filterModel[companies.publish_on_hold][values][]=false&filterModel[companies.publish_on_hold][filterType]=set&filterModel[companies.eligible_ratings_count][filterType]=number&filterModel[companies.eligible_ratings_count][type]=greaterThan&filterModel[companies.eligible_ratings_count][filter]=0",
      icon: "company",
      count: partially_publishable_companies_count,
    },
    {
      title: "Companies to be Reviewed",
      link: `/companies?filterModel[companies.next_review_date][filterType]=date&filterModel[companies.next_review_date][operator]=OR&filterModel[companies.next_review_date][condition1][dateFrom]=${today}&filterModel[companies.next_review_date][condition1][dateTo]=&filterModel[companies.next_review_date][condition1][filterType]=date&filterModel[companies.next_review_date][condition1][type]=lessThan&filterModel[companies.next_review_date][condition2][dateFrom]=&filterModel[companies.next_review_date][condition2][dateTo]=&filterModel[companies.next_review_date][condition2][filterType]=date&filterModel[companies.next_review_date][condition2][type]=blank&filterModel[companies.published_at][dateFrom]=&filterModel[companies.published_at][dateTo]=&filterModel[companies.published_at][filterType]=date&filterModel[companies.published_at][type]=dateTrue&filterModel[companies.published_status][filterType]=text&filterModel[companies.published_status][type]=contains&filterModel[companies.published_status][filter]=Full`,
      icon: "company",
      count: pending_admin_review_count,
    },
    {
      title: "Incomplete Companies -- Industry",
      link: "/companies?filterModel[companies.verified_ratings_percent][filterType]=number&filterModel[companies.verified_ratings_percent][type]=greaterThan&filterModel[companies.verified_ratings_percent][filter]=0&sortModel[][sort]=desc&sortModel[][colId]=industries.name",
      icon: "company",
      count: incomplete_companies_industry,
    },
    {
      title: "Incomplete Companies -- Size",
      link: "/companies?filterModel[companies.verified_ratings_percent][filterType]=number&filterModel[companies.verified_ratings_percent][type]=greaterThan&filterModel[companies.verified_ratings_percent][filter]=0&sortModel[][sort]=desc&sortModel[][colId]=companies.size",
      icon: "company",
      count: incomplete_companies_size,
    },
    {
      title: "Stale Headcount Updates Pending",
      link: `/companies?filterModel[headcounts.date][filterType]=date&filterModel[headcounts.date][operator]=OR&filterModel[headcounts.date][condition1][dateFrom]=${headcountDate}&filterModel[headcounts.date][condition1][dateTo]=&filterModel[headcounts.date][condition1][filterType]=date&filterModel[headcounts.date][condition1][type]=lessThan&filterModel[headcounts.date][condition2][dateFrom]=&filterModel[headcounts.date][condition2][dateTo]=&filterModel[headcounts.date][condition2][filterType]=date&filterModel[headcounts.date][condition2][type]=blank&filterModel[companies.published_at][dateFrom]=&filterModel[companies.published_at][dateTo]=&filterModel[companies.published_at][filterType]=date&filterModel[companies.published_at][type]=dateTrue&filterModel[parents_companies.name][filterType]=text&filterModel[parents_companies.name][type]=blank&filterModel[companies.published_status][filterType]=text&filterModel[companies.published_status][type]=contains&filterModel[companies.published_status][filter]=Full&sortModel[][sort]=desc&sortModel[][colId]=headcounts.date`,
      icon: "company",
      count: hc_updates_pending_count,
    },
  ]

  const qualitativeMetrics = [
    {
      title: "Rating Reviews Pending",
      link: "/reviews?filterModel[reviews.status][values][]=Pending&filterModel[reviews.status][filterType]=set",
      icon: "review",
      count: pending_reviews_count,
    },
    {
      title: "Review Responses Pending",
      link: "/reviews?filterModel[pending_responses_count][filterType]=number&filterModel[pending_responses_count][type]=equals&filterModel[pending_responses_count][filter]=1",
      icon: "review",
      count: pending_responses_count,
    },
    {
      title: "Questions Pending",
      link: "/questions?filterModel[questions.status][values][]=Pending&filterModel[questions.status][filterType]=set",
      icon: "question",
      count: pending_questions,
    },
    {
      title: "Answers Pending",
      link: "/questions?filterModel[pending_answers_count][filterType]=number&filterModel[pending_answers_count][type]=greaterThan&filterModel[pending_answers_count][filter]=0&sortModel[][sort]=desc&sortModel[][colId]=pending_answers_count",
      icon: "question",
      count: pending_answers,
    },
  ]

  const approvalMetrics = [
    {
      title: "Pending Review Approvals",
      link: "/approvals/reviews",
      count: pending_review_approvals_count,
    },
    {
      title: "Pending Question Approvals",
      link: "/approvals/questions",
      count: pending_question_approvals_count,
    },
    {
      title: "Pending Answer Approvals",
      link: "/approvals/answers",
      count: pending_answer_approvals_count,
    },
  ]

  const customerMetrics = [
    {
      title: "Pending Free Customer Approvals",
      link: "/customers/employers?filterModel[access_type][values][]=Free&filterModel[access_type][filterType]=set&filterModel[customers.status][values][]=Pending&filterModel[customers.status][filterType]=set&sortModel[][sort]=desc&sortModel[][colId]=customers.created_at",
      count: pending_free_customer_count,
    },
  ]

  const otherMetrics = [
    {
      title: "Draft Job Posts",
      link: "/jobs?filterModel[jobs.status][values][]=Draft&filterModel[jobs.status][filterType]=set",
      icon: "job",
      count: draft_jobs_count,
    },
    {
      title: "Pending Premium Job Posts",
      link: "/jobs?filterModel[jobs.status][values][]=Pending%20approval&filterModel[jobs.status][filterType]=set&filterModel[customers.access_type][values][]=Full%20Platform&filterModel[customers.access_type][values][]=Profile%20Only&filterModel[customers.access_type][filterType]=set",
      icon: "job",
      count: pending_premium_jobs_count,
    },
    {
      title: "Pending Free Job Posts",
      link: "/jobs?filterModel[jobs.status][values][]=Pending%20approval&filterModel[jobs.status][filterType]=set&filterModel[customers.access_type][values][]=Free&filterModel[customers.access_type][filterType]=set",
      icon: "job",
      count: pending_free_jobs_count,
    },
    {
      title: "Referrals - Pending Payment",
      link: "/referrals?filterModel[verified_at][dateFrom]=&filterModel[verified_at][dateTo]=&filterModel[verified_at][filterType]=date&filterModel[verified_at][type]=notBlank&filterModel[paid_at][dateFrom]=&filterModel[paid_at][dateTo]=&filterModel[paid_at][filterType]=date&filterModel[paid_at][type]=blank&filterModel[payment_requested_at][dateFrom]=&filterModel[payment_requested_at][dateTo]=&filterModel[payment_requested_at][filterType]=date&filterModel[payment_requested_at][type]=notBlank",
      count: payment_pending_count,
    },
    {
      title: "New to Sales - Pending Approval",
      link: "/users/new_to_sales?filterModel[status][values][]=Pending&filterModel[status][filterType]=set",
      icon: "users",
      count: pending_new_to_sales,
    },
  ]

  return (
    <>
      <div className="dashboard-header">
        <Header title="Operations Activity" icon="gauge" />
      </div>
      <div className="dashboard content">
        {loading ? (
          <Loading />
        ) : (
          <Container fluid>
            <section className="mb-3">
              <Row>
                <Col sm={4}>
                  <h5 className="operations-header">
                    <Icon name="ratings" />
                    Ratings
                  </h5>
                  <Row>
                    {ratingMetics.map(metric => {
                      const { title, link, count } = metric
                      return (
                        <Col sm={12} key={title}>
                          <OperationsCard
                            title={title}
                            link={link}
                            count={count}
                          />
                        </Col>
                      )
                    })}
                  </Row>
                </Col>
                <Col sm={8}>
                  <h5 className="operations-header">
                    <Icon name="questionFlag" />
                    Flagged for Questions
                  </h5>
                  <Row>
                    {questionFlagMetrics.map(metric => {
                      const { title, link, count } = metric
                      return (
                        <Col sm={6} key={title}>
                          <OperationsCard
                            title={title}
                            link={link}
                            count={count}
                          />
                        </Col>
                      )
                    })}
                  </Row>
                </Col>
              </Row>
            </section>
            <section className="mb-3">
              <h5 className="operations-header">
                <Icon name="company" />
                Companies
              </h5>
              <Row>
                {companyMetrics.map(metric => {
                  const { title, link, count } = metric

                  return (
                    <Col sm={4} key={title}>
                      <OperationsCard title={title} link={link} count={count} />
                    </Col>
                  )
                })}
              </Row>
            </section>
            <section className="mb-3">
              <h5 className="operations-header">
                <Icon name="review" />
                Qualitative Content
              </h5>
              <Row>
                {qualitativeMetrics.map(metric => {
                  const { title, link, count } = metric

                  return (
                    <Col sm={4} key={title}>
                      <OperationsCard title={title} link={link} count={count} />
                    </Col>
                  )
                })}
              </Row>
            </section>
            <section className="mb-3">
              <h5 className="operations-header">
                <Icon name="approval" />
                My Pending Approvals
              </h5>
              <Row>
                {approvalMetrics.map(metric => {
                  const { title, link, count } = metric
                  return (
                    <Col sm={4} key={title}>
                      <OperationsCard title={title} link={link} count={count} />
                    </Col>
                  )
                })}
              </Row>
            </section>
            <section className="mb-3">
              <h5 className="operations-header">
                <Icon name="customer" />
                Customers
              </h5>
              <Row>
                {customerMetrics.map(metric => {
                  const { title, link, count } = metric
                  return (
                    <Col sm={4} key={title}>
                      <OperationsCard title={title} link={link} count={count} />
                    </Col>
                  )
                })}
              </Row>
            </section>
            <section className="mb-3">
              <h5 className="operations-header">Other</h5>
              <Row>
                {otherMetrics.map(metric => {
                  const { title, link, count } = metric

                  return (
                    <Col sm={4} key={title}>
                      <OperationsCard title={title} link={link} count={count} />
                    </Col>
                  )
                })}
              </Row>
            </section>
          </Container>
        )}
      </div>
    </>
  )
}

export default OperationsRoute
